<template>
  <div class="outermostElder">
    <layout tit="军 创 通" :hd="true" :ft="true">
      <!-- 右边按键 -->
      <div slot="rt"></div>
      <!-- 左边按键 -->
      <div slot="le" class="hd-left">
        <!-- <div class="QR-code"></div>
        <span>门禁码</span> -->
      </div>
      <!-- 四个控件 -->
      <div class="select-function" v-if="FixedTopList.length">
        <router-link
          v-for="item in FixedTopList"
          :key="item.id"
          :to="{ name: 'comprehensive', params: { id: item.id } }"
          class="comprehensive-content-s"
        >
          <div>
            <img :src="item.iconUrl" alt="" />
            <span>{{ item.name }}</span>
          </div>
        </router-link>

        <router-link
          :to="{ name: 'curriculum', params: { type: 'skill' } }"
          class="comprehensive-content-s"
        >
          <div>
            <img src="@/assets/img/home/education-2x.png" alt="" />
            <span>学历&技能培训</span>
          </div>
        </router-link>
        <router-link
          :to="{ name: 'curriculum', params: { type: 'adult' } }"
          class="comprehensive-content-s"
        >
          <div>
            <img src="@/assets/img/home/grow-up-2x.png" alt="" />
            <span>成长培训</span>
          </div>
        </router-link>
      </div>
      <div class="select-function" v-else>
        <div class="comprehensive-content-s"></div>

        <div class="comprehensive-content-s"></div>

        <router-link
          :to="{ name: 'curriculum', params: { type: 'skill' } }"
          class="comprehensive-content-s"
        >
          <div>
            <img src="@/assets/img/home/education-2x.png" alt="" />
            <span>学历&技能培训</span>
          </div>
        </router-link>
        <router-link
          :to="{ name: 'curriculum', params: { type: 'adult' } }"
          class="comprehensive-content-s"
        >
          <div>
            <img src="@/assets/img/home/grow-up-2x.png" alt="" />
            <span>成长培训</span>
          </div>
        </router-link>
      </div>
      <!-- banner -->

      <van-swipe
        v-if="afficheList.length != 0"
        style="height: 101px"
        :autoplay="2000"
        loop
        vertical
        :show-indicators="false"
      >
        <van-swipe-item v-for="item in afficheList" :key="item.id"
          ><div class="banner">
            <div class="banner-top">
              <div>公告</div>
              <div class="vertical-bar-first"></div>
              <div class="vertical-bar-second"></div>
              <div class="vertical-bar-third"></div>
            </div>
            <div class="banner-bottom" @click="look">
              <div class="title">{{ item.title }}</div>
              <van-button type="primary">查看</van-button>
            </div>
          </div></van-swipe-item
        >
      </van-swipe>
      <div v-else class="placeholder"></div>

      <!-- 找商机 -->
      <div class="business-opportunity">
        <div class="business-opportunity-title">
          <div class="business-opportunity-title-left">找商机</div>
          <div
            class="business-opportunity-title-right"
            @click="jumpPage('/home/activity/activity-list')"
          >
            <span>查看全部</span>
            <div></div>
          </div>
        </div>
        <div class="slide">
          <router-link
            :to="{ name: 'activity-list', params: { id: 1 } }"
            class="slide-box"
          >
            <div class="slide-box-img">
              <img src="@/assets/img/home/Club.png" alt="" />
            </div>
            <div class="slide-box-content">军创俱乐部</div>
          </router-link>
          <router-link
            :to="{ name: 'activity-list', params: { id: 2 } }"
            class="slide-box"
          >
            <div class="slide-box-img">
              <img src="@/assets/img/home/marketing.png" alt="" />
            </div>
            <div class="slide-box-content">营销沙龙</div>
          </router-link>
          <router-link
            :to="{ name: 'activity-list', params: { id: 3 } }"
            class="slide-box"
          >
            <div class="slide-box-img">
              <img src="@/assets/img/home/afternoon.png" alt="" />
            </div>
            <div class="slide-box-content">下午茶</div>
          </router-link>
        </div>
      </div>
      <!-- 综合服务 -->
      <div class="comprehensive">
        <div class="comprehensive-title">综合服务</div>
        <van-swipe class="my-swipe" indicator-color="white" @change="onChange">
          <van-swipe-item v-for="index of pages" :key="index"
            ><div class="comprehensive-content">
              <router-link
                v-for="item in ArticleTypeList"
                :key="item.id"
                :to="{ name: 'comprehensive', params: { id: item.id } }"
                class="comprehensive-content-s"
              >
                <img :src="item.iconUrl" alt="" />
                <div>
                  <div class="comprehensive-content-top">
                    <div>{{ item.name }}</div>
                  </div>
                  <div class="comprehensive-content-bottom">
                    {{ item.remark }}
                  </div>
                </div>
              </router-link>
              <!-- <router-link
            :to="{ name: 'comprehensive', params: { id: 3 } }"
            class="comprehensive-content-s"
          >
            <div class="comprehensive-content-top">
              <img src="@/assets/img/home/finance-tax-2x.png" alt="" />
              <div>财税服务</div>
            </div>
            <div class="comprehensive-content-bottom">代理记账 税务筹划</div>
          </router-link>
          <router-link
            :to="{ name: 'comprehensive', params: { id: 4 } }"
            class="comprehensive-content-s"
          >
            <div class="comprehensive-content-top">
              <img src="@/assets/img/home/financing-2x.png" alt="" />
              <div>融资服务</div>
            </div>
            <div class="comprehensive-content-bottom">股权融资 信贷融资</div>
          </router-link>
          <router-link
            :to="{ name: 'comprehensive', params: { id: 5 } }"
            class="comprehensive-content-s"
          >
            <div class="comprehensive-content-top">
              <img src="@/assets/img/home/law-2x.png" alt="" />
              <div>法律服务</div>
            </div>
            <div class="comprehensive-content-bottom">股权架构 文书服务</div>
          </router-link>
          <router-link
            :to="{ name: 'comprehensive', params: { id: 6 } }"
            class="comprehensive-content-s"
          >
            <div class="comprehensive-content-top">
              <img src="@/assets/img/home/number-2x.png" alt="" />
              <div>数字营销</div>
            </div>
            <div class="comprehensive-content-bottom">品牌战略 营销策划</div>
          </router-link>
          <router-link
            :to="{ name: 'comprehensive', params: { id: 7 } }"
            class="comprehensive-content-s"
          >
            <div class="comprehensive-content-top">
              <img src="@/assets/img/home/science-technology-2x.png" alt="" />
              <div>科技申报</div>
            </div>
            <div class="comprehensive-content-bottom">高新认定 项目申报</div>
          </router-link>
          <router-link
            :to="{ name: 'comprehensive', params: { id: 8 } }"
            class="comprehensive-content-s"
          >
            <div class="comprehensive-content-top">
              <img src="@/assets/img/home/design-2x.png" alt="" />
              <div>设计服务</div>
            </div>
            <div class="comprehensive-content-bottom">功能描述 功能描述</div>
          </router-link>
          <router-link
            :to="{ name: 'comprehensive', params: { id: 9 } }"
            class="comprehensive-content-s"
          >
            <div class="comprehensive-content-top">
              <img src="@/assets/img/home/technology-2x.png" alt="" />
              <div>技术服务</div>
            </div>
            <div class="comprehensive-content-bottom">功能描述 功能描述</div>
          </router-link>
          <router-link
            :to="{ name: 'comprehensive', params: { id: 0 } }"
            class="comprehensive-content-s"
          >
            <div class="comprehensive-content-top">
              <img src="@/assets/img/home/human-resources-2x.png" alt="" />
              <div>人力资源</div>
            </div>
            <div class="comprehensive-content-bottom">功能描述 功能描述</div>
          </router-link> -->
            </div></van-swipe-item
          >
        </van-swipe>
              <div class="bottom">
          <p>本服务由浙江政务服务网,西湖区卫生健康局提供</p>
          <p>
            服务咨询热线:
            <span @click="phoneCall('0571-88808880')">0571-88808880</span>
          </p>
        </div>
      </div>
  
    </layout>
  </div>
</template>

<script>
// import { login } from '@/api/login'
// import { setItem } from '@/utils/auth'
import Layout from '@/components/layout.vue'
import {
  getAfficheData,
  getFixedTopArticleTypeList,
  getArticleTypePage
} from '@/api/home'

export default {
  components: {
    Layout
  },
  data() {
    return {
      afficheList: [],
      FixedTopList: [],
      ArticleTypeList: [],
      pageNo: 1,
      pageSize: 8,
      pages: 1
    }
  },
  methods: {
    onChange(index) {
      this.pageNo = index + 1
      this.getArticleTypePage()
    },
    look() {
      this.$router.push('/affiche')
    },
    jumpPage(url) {
      this.$router.push(url)
    },
     phoneCall(corpId) {
      const sUserAgent = window.navigator.userAgent.toLowerCase()
      const bIsDtDreamApp = sUserAgent.indexOf('dtdreamweb') > -1 // 浙里办APP
      // 浙里办支付宝小程序
      if (!bIsDtDreamApp) {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = 'tel:' + corpId
        a.click()
        a.remove()
      } else {
        return new Promise((resolve, reject) => {
          ZWJSBridge.onReady(() => {
            ZWJSBridge.phoneCall({ corpId })
              .then((res) => {
                resolve({ success: true, data: res })
              })
              .catch((error) => {
                resolve({
                  success: false,
                  errMsg: JSON.stringify(error),
                  data: {}
                })
              })
          })
        })
      }
    },
    getAfficheData() {
      getAfficheData({
        pageNo: 1,
        pageSize: 10
      }).then((res) => {
        this.afficheList = res.result.records
      })
    },
    // getLongin() {
    //   login({
    //     username: 'admin',
    //     password: 123456
    //     // username: 'demo123',
    //     // password: 'demo123..'
    //   }).then((res) => {
    //     setItem('Admin-Token', res.result.token)
    //     this.$store.commit('user/setUserInfo', res.result.userInfo)
    //     // console.log(this.$store.state.user.userInfo.realname)
    //   })
    // }
    getFixedTopArticleTypeList() {
      getFixedTopArticleTypeList().then((res) => {
        this.FixedTopList = res.result
      })
    },
    getArticleTypePage() {
      getArticleTypePage({
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then((res) => {
        this.pages = res.result.pages
        this.ArticleTypeList = res.result.records
      })
    }
  },
  mounted() {},
  created() {
    // this.getLongin()
    this.getAfficheData()
    this.getFixedTopArticleTypeList()
    this.getArticleTypePage()
  }
}
</script>

<style lang="less" scoped>
.outermostElder {
  background-color: #fff;
  .hd-left {
    display: flex;
    align-items: center;
    .QR-code {
      width: 20px;
      height: 20px;
      background-color: #fff;
    }
    span {
      font-size: 12px;
      margin-left: 13px;
    }
  }
  .select-function {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 26px;
    margin-top: 25px;
    margin-bottom: 30px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 150px;
      min-height: 81px;
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      text-align: CENTER;
      color: #333333;
      letter-spacing: 0.02px;
      margin-bottom: 24px;
      img {
        width: 56px;
        height: 56px;
      }
    }
  }
  .placeholder {
    height: 101px;
  }
  .banner {
    height: 101px;
    background: url('~@/assets/img/home/banner-2x.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .banner-top {
      margin-left: 14px;
      margin-top: 14px;
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      text-align: LEFT;
      color: #12151b;
      letter-spacing: 0.02px;
      display: flex;
      align-items: flex-end;
      .vertical-bar-first {
        width: 1px;
        height: 15px;
        border: 1px solid #10955b;
        background-color: #10955b;
        margin-left: 12px;
      }
      .vertical-bar-second {
        width: 1px;
        height: 8px;
        border: 1px solid #10955b;
        background-color: #10955b;
        margin: 0 5px;
      }
      .vertical-bar-third {
        width: 1px;
        height: 10px;
        border: 1px solid #10955b;
        background-color: #10955b;
      }
    }
    .banner-bottom {
      margin-left: 14px;
      margin-bottom: 14px;
      margin-right: 14px;
      font-size: 14px;
      color: #868a93;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #464a55;
        letter-spacing: 0.01px;
      }
      .van-button--normal {
        width: 79px;
        height: 36px;
        background: linear-gradient(90deg, #25c794, #20ce82, #14ab69 100%);
        border-radius: 18px;
        font-size: 18px;
      }
    }
  }
  .business-opportunity {
    margin-top: 32px;
    padding-left: 15px;
    .business-opportunity-title {
      font-size: 14px;
      color: #868a93;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .business-opportunity-title-left {
        font-size: 24px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        text-align: LEFT;
        color: #12151b;
        letter-spacing: 0.02px;
      }
      .business-opportunity-title-right {
        display: flex;
        align-items: center;
        margin-right: 18px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: RIGHT;
        color: #868a93;
        letter-spacing: 0.01px;
        div {
          width: 5px;
          height: 5px;
          border-top: 1px solid #868a93;
          border-right: 1px solid #868a93;
          transform: rotate(45deg);
          margin-left: 5px;
        }
      }
    }
    .slide {
      margin-top: 12px;
      white-space: nowrap;
      overflow-y: auto;
      .slide-box {
        // width: 202px;
        margin-left: 3%;
        // height: 135px;

        display: inline-block;
        .slide-box-img {
          width: 77px;
          height: 77px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .slide-box-content {
          text-align: center;
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Bold;
          font-weight: 700;
          color: #333333;
          letter-spacing: 0.01px;
          margin-top: 9px;
        }
      }
    }
    .slide::-webkit-scrollbar {
      display: none; //隐藏滚动条
    }
  }
  .comprehensive {
    margin-top: 20px;
    .comprehensive-title {
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      text-align: LEFT;
      color: #12151b;
      letter-spacing: 0.02px;
      padding-left: 15px;
    }
    .comprehensive-content {
      min-height: 285px;
      padding: 0 31px;

      // justify-content: flex-start;

      .comprehensive-content-s {
          div{
              margin-left: 10px;
          }
        display: flex;
        margin-top: 21px;
        img {
          width: 56px;
          height: 56px;
        }
      }
      .comprehensive-content-top {
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        text-align: LEFT;
        color: #12151b;
        letter-spacing: 0.02px;
        img {
          width: 24px;
          height: 24px;
        }
        div {
          margin-left: 11px;
          font-size: 16px;
          color: #12151b;
        }
      }
      .comprehensive-content-bottom {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: LEFT;
        color: #868a93;
        letter-spacing: 0.01px;
        margin-top: 7px;
      }
    }
  }
}
.bottom {
  margin-top: 20px;
  color: #868a93;
  p {
    font-size: 16px;
    text-align: center;
  }
  span {
    font-size: 16px;
    color: #3c43b3;
  }
}
</style>