<template>
  <div>
   <normal v-if="!$store.state.user.elderModel"></normal>
   <Elder v-else></Elder>
  </div>
</template>

<script>
import normal from './isElder/normal.vue'
import Elder from './isElder/Elder.vue'
export default {
  components: {
    normal,
    Elder
  },
 
}
</script>

<style lang="less" scoped>


</style>